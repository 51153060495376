import { ReactComponent as Question } from '../../assets/icons/question.svg'
import { ReactComponent as SelectArrow } from '../../assets/icons/selectArrow.svg'
import { ReactComponent as Settings } from '../../assets/icons/settings.svg'
import { ReactComponent as Cross } from '../../assets/icons/cross.svg'
import { ReactComponent as Logo } from '../../assets/icons/logo.svg'
import { ReactComponent as Copy } from '../../assets/icons/copy.svg'
import { ReactComponent as Columns } from '../../assets/icons/columns.svg'
import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg'
import { ReactComponent as ArrowUp } from '../../assets/icons/arrowUp.svg'
import { ReactComponent as ArrowRight } from '../../assets/icons/arrowRight.svg'
import { ReactComponent as Filter } from '../../assets/icons/filter.svg'
import { ReactComponent as Tick } from '../../assets/icons/tick.svg'
import { ReactComponent as Info } from '../../assets/icons/info.svg'
import { ReactComponent as Download } from '../../assets/icons/download.svg'
import { ReactComponent as Play } from '../../assets/icons/play.svg'
import { ReactComponent as Pause } from '../../assets/icons/pause.svg'
import { ReactComponent as NoResults } from '../../assets/icons/noResults.svg'
import { ReactComponent as Volume } from '../../assets/icons/volume.svg'
import { ReactComponent as Microphone } from '../../assets/icons/microphone.svg'
import { ReactComponent as Documentation } from '../../assets/icons/doc.svg'
import { ReactComponent as Female } from '../../assets/icons/female.svg'
import { ReactComponent as Male } from '../../assets/icons/male.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit.svg'
import { ReactComponent as Examples } from '../../assets/icons/examples.svg'
import { ReactComponent as Back } from '../../assets/icons/back.svg'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'
import { ReactComponent as Clear } from '../../assets/icons/clear.svg'
import { ReactComponent as AsrLogo } from '../../assets/icons/asrLogo.svg'
import { ReactComponent as TtsLogo } from '../../assets/icons/ttsLogo.svg'
import { ReactComponent as HideIcon } from '../../assets/icons/hide_icon.svg'
import { ReactComponent as ShowIcon } from '../../assets/icons/show_icon.svg'
import { ReactComponent as ConsoleLogo } from '../../assets/icons/console.svg'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import { ReactComponent as Confidence } from '../../assets/icons/confidence.svg'
import { ReactComponent as FilterSettingsIcon } from '../../assets/icons/filterSettingsIcon.svg'
import { ReactComponent as ConsoleErrorIcon } from '../../assets/icons/consoleError.svg'
import { ReactComponent as CloackIcon } from '../../assets/icons/cloackIcon.svg'
import { ReactComponent as ShearsIcon } from '../../assets/icons/shearsIcon.svg'
import { ReactComponent as PenIcon } from '../../assets/icons/penIcon.svg'
import { FC } from 'react'

export type TIcon =
  | 'question'
  | 'selectArrow'
  | 'settings'
  | 'cross'
  | 'logo'
  | 'copy'
  | 'columns'
  | 'arrowDown'
  | 'arrowUp'
  | 'arrowRight'
  | 'filter'
  | 'tick'
  | 'info'
  | 'download'
  | 'play'
  | 'pause'
  | 'noResults'
  | 'volume'
  | 'microphone'
  | 'doc'
  | 'female'
  | 'male'
  | 'edit'
  | 'examples'
  | 'back'
  | 'logout'
  | 'clear'
  | 'asrLogo'
  | 'ttsLogo'
  | 'hideIcon'
  | 'showIcon'
  | 'consoleLogo'
  | 'calendar'
  | 'confidence'
  | 'filterSettingsIcon'
  | 'consoleError'
  | 'cloackIcon'
  | 'shearsIcon'
  | 'penIcon'

export const iconConfig: { [p in TIcon]: FC } = {
  question: Question,
  selectArrow: SelectArrow,
  settings: Settings,
  cross: Cross,
  logo: Logo,
  copy: Copy,
  columns: Columns,
  clear: Clear,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  filter: Filter,
  tick: Tick,
  download: Download,
  info: Info,
  noResults: NoResults,
  play: Play,
  pause: Pause,
  volume: Volume,
  microphone: Microphone,
  doc: Documentation,
  female: Female,
  male: Male,
  edit: Edit,
  examples: Examples,
  back: Back,
  logout: Logout,
  asrLogo: AsrLogo,
  ttsLogo: TtsLogo,
  consoleLogo: ConsoleLogo,
  hideIcon: HideIcon,
  showIcon: ShowIcon,
  arrowRight: ArrowRight,
  calendar: Calendar,
  confidence: Confidence,
  filterSettingsIcon: FilterSettingsIcon,
  consoleError: ConsoleErrorIcon, 
  cloackIcon: CloackIcon,
  shearsIcon: ShearsIcon,
  penIcon: PenIcon
}
