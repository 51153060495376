import classes from '../styles/tts/ttsModal.module.scss'
import { FC } from 'react'

export const LanguageIcons: FC<{ languages: string[] }> = ({ languages }) => {
  return (
    <>
      {languages.map((item, idx) => (
        <div key={item + '_' + idx} className={classes.ttsModalVoiceLanguage}>
          {item === 'ru' ? 'РУ' : item.toUpperCase()}
        </div>
      ))}
    </>
  )
}
