import { Dispatch, FC, memo, SetStateAction, useCallback, useState, useEffect } from 'react'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { IMessage } from '../../@types/asr'

import classes from '../../styles/asr/asrMessage.module.scss'
import { asrDeleteMessage, asrHideShowMessage, asrSetAudio } from '../../store/slices/asr/asrSlice'
import { useDispatch } from 'react-redux'
import { PlayerV2, THandlePlayerChangeProps } from '../../components/player/playerV2'
import { useSelector } from '../../hooks/redux'
import WaveSurfer from 'wavesurfer.js'
import { CustomButtonSelect } from '../../components/customButtonSelect'
import { mainSettingsTranslate, additionalAsrConfig, languageTranslate } from './settings/config'
import { useMediaQuery } from 'react-responsive'
import { MobilePopup } from '../../components/mobilePopup'
import modalClasses from '../../styles/mobilePopup.module.scss'

const MessageHeaderInfo: FC<{ item: IMessage }> = memo(({ item }) => {
  const settings = item?.asrSettings && Object.keys(item.asrSettings)
  const mainSettings = settings?.filter((item) => mainSettingsTranslate[item])
  const additional = settings?.filter(
    (key) => !mainSettings.includes(key) && (item.asrSettings as any)?.[key] && key !== 'vad_type'
  )

  return (
    <div className={classes.messageHeaderInfo}>
      <div className={classes.mainSettingsContainer}>
        {mainSettings?.map((key, index) => {
          let title = (item.asrSettings as any)[key]
          if (key === 'language')
            title = languageTranslate[(item.asrSettings as any)[key]] || (item.asrSettings as any)[key]
          if (key === 'stream_recognition') title = (item.asrSettings as any)[key] ? 'Потоковое' : 'Пакетное'

          return (
            <div className={classes.mainSetting} key={key + index}>
              <span className={classes.mainSettingTitle}>{mainSettingsTranslate[key]}</span>{' '}
              <span className={classes.divider} />
              {title}
            </div>
          )
        })}
      </div>
      {!!additional?.length && (
        <div className={classes.additionalSettingWrapper}>
          {additional?.map((key, index) => {
            const translate = additionalAsrConfig.find((item) => item.name === key)?.title

            return (
              <div className={classes.additionalSetting} key={key + index}>
                <span>
                  <CustomIcon icon="tick" />
                </span>
                {translate}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
})

MessageHeaderInfo.displayName = 'MessageHeaderInfo'

export const MessageHeader: FC<{ item: IMessage; setPlayer: Dispatch<SetStateAction<null | WaveSurfer>> }> = memo(
  ({ item, setPlayer }) => {
    const dispatch = useDispatch()
    const id = item.id
    const currentAudioId = useSelector((state) => state.asr.currentAudio.id)
    const isMediumScreen = useMediaQuery({ query: '(max-width: 1280px)' })
    const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })
    const [showPopup, setShowPopup] = useState(false)
    const [containerWidth, setContainerWidth] = useState(0)

    const handleAudioChange = useCallback(
      ({ currentTime }: THandlePlayerChangeProps) => {
        dispatch(asrSetAudio({ currentTime, id }))
      },
      [dispatch, id]
    )

    const handlePlay = (waveSurfer: WaveSurfer) => {
      const totalTime = waveSurfer.current?.getDuration()
      const currentTime = waveSurfer.current?.getCurrentTime()

      dispatch(asrSetAudio({ currentTime, totalTime, id }))
    }

    useEffect(() => {
      const handleResize = () => {
        const parentElement = document.getElementById('settingsWrapper')
        if (parentElement) {
          const width = parentElement.offsetWidth
          setContainerWidth(width)
        }
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])

    return (
      <>
        <div className={classes.asrMessageHeader}>
          <div className={classes.asrMessageFilename}>
            <button onClick={() => dispatch(asrHideShowMessage({ id }))}>
              <CustomIcon icon={item.isMessageOpen ? 'arrowDown' : 'arrowRight'} />
            </button>
            <span title={item?.filename}>{item?.filename}</span>
          </div>
          <div className={classes.asrMessageSettingsWrapper}>
            {!isMediumScreen && (
              <div
                style={{
                  width: 500,
                }}
                id="settingsWrapper"
              >
                <PlayerV2
                  url={item.audio_url}
                  stopPlayer={currentAudioId !== id}
                  handleOnSeek={handleAudioChange}
                  handleChange={handleAudioChange}
                  handlePlay={handlePlay}
                  setPlayer={setPlayer}
                  containerWidth={containerWidth}
                />
              </div>
            )}
            <div className={classes.asrMessageSettings}>
              <CustomButtonSelect
                onClick={() => isLaptopOrMobile && setShowPopup(true)}
                hideSelect={isLaptopOrMobile}
                style={{ padding: 0 }}
                contentComponent={<MessageHeaderInfo item={item} />}
              >
                <CustomIcon icon="info" />
              </CustomButtonSelect>
              <a download href={item.audio_url} rel="noreferrer" target="_blank">
                <CustomIcon icon="download" />
              </a>
              <button onClick={() => dispatch(asrDeleteMessage({ id }))}>
                <CustomIcon icon="cross" />
              </button>
            </div>
          </div>
        </div>
        {isMediumScreen && (
          <div id="settingsWrapper" className={classes.asrPlayerSmallWrapper}>
            <PlayerV2
              url={item.audio_url}
              stopPlayer={currentAudioId !== id}
              handleOnSeek={handleAudioChange}
              handleChange={handleAudioChange}
              handlePlay={handlePlay}
              setPlayer={setPlayer}
              containerWidth={containerWidth}
            />
          </div>
        )}
        {isLaptopOrMobile && (
          <MobilePopup show={showPopup} handleClose={() => setShowPopup(false)}>
            <div className={modalClasses.modalPopupTitleWrapper}>
              <div className={modalClasses.mobilePopupTitle}>Иформация о записи</div>
              <button onClick={() => setShowPopup(false)} className={modalClasses.mobilePopupCloseBtn}>
                Закрыть
              </button>
            </div>
            <MessageHeaderInfo item={item} />
          </MobilePopup>
        )}
      </>
    )
  }
)
