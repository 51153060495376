import asrClasses from '../../styles/asr/asrSidebar.module.scss'
import React, { FC } from 'react'

export const SwitchCheckbox: FC<{ checked: boolean; name: string; onChange: (e: React.ChangeEvent) => void }> = ({
  checked,
  onChange,
  name,
}) => {
  return (
    <label className={asrClasses.checkBoxItem}>
      <input name={name} checked={checked} type="checkbox" onChange={onChange} />
      <span className={asrClasses.checkBoxAsr} />
    </label>
  )
}
