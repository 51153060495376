import { FC, memo } from 'react'
import cn from 'classnames'
import { CustomIcon } from '../../components/customIcon/customIcon'

import classes from '../../styles/asr/asrMessage.module.scss'

export const StreamingRecordButton: FC<{
  isLoading?: boolean
  isRecording?: boolean
  classes?: { button?: string; buttonWrapper?: string }
}> = memo(({ isLoading, isRecording, classes: classNames }) => {
  return (
    <div
      className={cn(
        classes.streamingBtnWrapper,
        isLoading && classes.streamingBtnWrapperLoading,
        classNames?.buttonWrapper && classNames.buttonWrapper
      )}
    >
      <button className={cn(classNames?.button && classNames.button, classes.streamingBtn)}>
        {(isRecording || isLoading) && <div className={classes.streamingBtnSquare} />}
        {!isLoading && !isRecording && (
          <div className={classes.microphone}>
            <CustomIcon icon="microphone" />
          </div>
        )}
      </button>
      {isRecording && (
        <>
          <div className={classes.streamingBtnShade} />
          <div className={cn(classes.streamingBtnShade, classes.shade2)} />
        </>
      )}
    </div>
  )
})
