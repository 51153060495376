import { configureStore } from '@reduxjs/toolkit'
import asrReducer from './slices/asr/asrSlice'
import playerTtsReducer from './slices/playerTts/playerTtsSlice'
import ttsReducer from './slices/tts/ttsSlice'
import userReducer from './slices/user/userSlice'
import notifyReducer from './slices/notify/notifySlice'
import consoleReducer from './slices/statisticsConsole/consoleSlice'

export const reducer = {
  asr: asrReducer,
  tts: ttsReducer,
  console: consoleReducer,
  playerTts: playerTtsReducer,
  user: userReducer,
  notify: notifyReducer,
}

export const store = configureStore({
  reducer,
  middleware: (defaultMiddleWare) => defaultMiddleWare({ serializableCheck: false }),
})
