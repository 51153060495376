import RecordRTC, { StereoAudioRecorder } from 'recordrtc'
import { useDispatch } from 'react-redux'
import { urlAsr, webSocketUrl } from '../../config'
import { asrStreamingUploadSuccess, asrUpdateStream } from '../../store/slices/asr/asrSlice'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from '../../hooks/redux'
import { TStreamingInfo } from '../../@types/asr'

export const useStreamRecord = () => {
  const dispatch = useDispatch()
  const ws = useRef<null | WebSocket>(null)
  const streamRef = useRef<null | MediaStream>(null)
  const recorderRef = useRef<null | RecordRTC>(null)
  const otherStreamOptions = useRef<null | { audio_url: string }>({ audio_url: '' })
  const isRecordStop = useSelector((state) => state.asr.stream.isRecordStop)
  const asrSettings = useSelector((state) => state.asr.selectedAsrSettings)
  const token = useSelector((state) => state.user.user.auth_token)
  const notFinalText = useRef<TStreamingInfo | {}>({})
  const finalText = useRef<TStreamingInfo[]>([])

  const init = useMemo(
    () => ({
      ...asrSettings,
      auth_type: 'Basic',
      get_session_id: true,
      auth_token: token,
    }),
    [asrSettings, token]
  )

  const renderMessage = useCallback(() => {
    const textFilterd = finalText.current.map((item) => item.text).join(' ')
    const text = finalText.current.reduce((prev: any[], next) => [...prev, ...next.words], [])
    notFinalText.current = {}
    dispatch(
      asrStreamingUploadSuccess({ textFilterd, text: [text], audio_url: otherStreamOptions?.current?.audio_url })
    )
    finalText.current = []
  }, [dispatch])

  const closeStream = useCallback(() => {
    if (!ws?.current) return

    ws.current?.close()
    ws.current = null

    if (finalText.current?.length) renderMessage()

    dispatch(asrUpdateStream({ isStreaming: false, data: { final: [], notFinal: {} }, isRecordStop: true }))
  }, [dispatch, renderMessage])

  const startConnection = () => {
    ws.current = new WebSocket(webSocketUrl as string)

    ws.current?.addEventListener('open', (d) => {
      const sample_rate = new AudioContext().sampleRate || '48000'
      ws.current?.send(JSON.stringify({ ...init, sample_rate }))
    })

    ws.current?.addEventListener('message', (event) => {
      const data = JSON.parse(event.data)

      if (data?.session_id && otherStreamOptions.current) {
        const sessionId = data.session_id
        otherStreamOptions.current.audio_url = urlAsr + `/records?token=${token}&session_id=${sessionId}`
      }

      if (!data?.results) return

      const dataToDraw = data.results.find((item: any) => item.event === 3)
      const final = data.results.filter((item: any) => item.event === 3 && item.final === true)

      if (final.length) {
        let newFinal = finalText.current
        if (final?.[0]?.alternatives?.[0]) newFinal = [...finalText.current, final?.[0]?.alternatives?.[0]]

        finalText.current = newFinal
        dispatch(
          asrUpdateStream({
            data: { final: newFinal, notFinal: {} },
          })
        )

        if (!recorderRef.current) closeStream()
        return
      }

      if (!recorderRef.current && !data?.results.length) return closeStream()

      const newNotFinal = dataToDraw?.alternatives?.[0] ?? {}

      dispatch(
        asrUpdateStream({
          data: {
            final: finalText.current,
            notFinal: newNotFinal,
          },
        })
      )
      notFinalText.current = newNotFinal
    })

    ws.current?.addEventListener('error', (e) => {
      notFinalText.current = {}
      finalText.current = []
      dispatch(asrUpdateStream({ isStreaming: false, data: { final: [], notFinal: {} }, isRecordStop: true }))
    })

    ws.current?.addEventListener('close', (event) => {
      if (finalText.current?.length) renderMessage()

      notFinalText.current = {}
      finalText.current = []
      dispatch(asrUpdateStream({ isStreaming: false, data: { final: [], notFinal: {} }, isRecordStop: true }))
    })
  }

  const startListening = async () => {
    const device = navigator.mediaDevices.getUserMedia({ audio: true })

    device.then((stream) => {
      streamRef.current = stream
      startConnection()
      const ctx = new AudioContext().sampleRate || 48000

      const recorder = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/wav',
        timeSlice: 60,
        disableLogs: true,
        numberOfAudioChannels: 1,
        sampleRate: ctx,
        recorderType: StereoAudioRecorder,
        ondataavailable: async (blob) => {
          if (!ws.current) return

          if (ws.current.readyState === 1) {
            ws.current?.send(blob.slice(44))
          }
        },
      })
      recorderRef.current = recorder
      recorder.startRecording()
      dispatch(asrUpdateStream({ isStreaming: true, isRecordStop: false }))
    })
  }

  useEffect(() => {
    if (isRecordStop && recorderRef?.current) {
      recorderRef?.current?.stopRecording()
      streamRef?.current?.getTracks().forEach((track) => track.stop())
      streamRef.current = null
      recorderRef.current = null
      ws.current?.send('/EOS')
    }
  }, [closeStream, isRecordStop])

  return {
    startListening,
    closeStream,
  }
}
