import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorageInfo, setLocalStorageInfo } from '../../../tools'
import { login } from './async'
import { IUser } from '../../../@types/user'

const initialUser: IUser = {
  auth_token: '',
  user_id: 0,
  access_admin: false,
  access_asr: false,
  access_console: false,
  access_tts: false,
  auth_username: '',
}

const initialState: {
  user: IUser
  loading: boolean
} = {
  loading: false,
  user: getLocalStorageInfo('user') || initialUser,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserStore: (state, action) => {
      setLocalStorageInfo('user', { ...getLocalStorageInfo('user'), ...action.payload })
      return { ...state, ...action.payload }
    },
    logout: (state) => {
      setLocalStorageInfo('user', { ...getLocalStorageInfo('user'), ...initialUser })
      return { ...state, user: initialUser }
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload }
    },
  },
  extraReducers: (build) => {
    build.addCase(login.pending, (state) => {
      state.loading = true
    })
    build.addCase(login.fulfilled, (state, action: PayloadAction<(IUser & { response_code: number }) | null>) => {
      if (!action.payload) return state

      const { response_code, ...user } = action.payload

      try {
        setLocalStorageInfo('user', { ...getLocalStorageInfo('user'), ...user })
        return { ...state, user, loading: false }
      } catch (e) {
        return { ...state, loading: false }
      }
    })
    build.addCase(login.rejected, (state) => {
      state.loading = false
    })
  },
})

const { actions, reducer } = userSlice

export const { setUserStore, logout, setLoading } = actions

export default reducer
