import { ReactPortal } from './reactPortal'
import { FC, useState, useRef, ReactElement, ReactNode, useEffect, memo } from 'react'
import classes from '../styles/customDialog.module.scss'
import cn from 'classnames'
import { TPlacement } from './tooltip/portalTooltip'
import { transformConfig } from './tooltip/portalTooltip'
import { OutClick } from './outClick'

export const CustomDialog: FC<{
  children: ReactElement
  placement?: TPlacement
  showDelay?: number
  showDialog: boolean
  handleClick: () => void
  stayDialogHover?: boolean
  hideTooltip?: boolean
  dialogContent: ReactNode
  offset?: number
  classes?: {
    contentClassName?: string
    tooltipWrapperClassName?: string
    childrenWrapperClassName?: string
  }
  triangle?: boolean
}> = memo(
  ({
    children,
    placement = 'top-middle',
    showDelay = 50,
    hideTooltip,
    showDialog,
    handleClick,
    dialogContent,
    offset = 8,
    classes: classNames,
    triangle = true,
  }) => {
    const { contentClassName } = classNames || {}
    const [pos, setPos] = useState({ x: 0, y: 0, active: false })
    const ref = useRef<HTMLDivElement>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const onMouseEnter = () => {
      if (!ref.current || pos.active || !dialogContent || typeof window === 'undefined') return
      timeoutRef.current && clearTimeout(timeoutRef.current)
      let x = ref.current.getBoundingClientRect().x + ref.current.clientWidth / 2
      let y = ref.current.getBoundingClientRect().y + window.scrollY

      if (placement === 'bottom-middle') y = y + ref.current.clientHeight
      if (placement === 'right-middle') {
        x = ref.current.getBoundingClientRect().x + ref.current.clientWidth
        y = y + ref.current.clientHeight / 2
      }
      if (placement === 'left-middle') {
        x = ref.current.getBoundingClientRect().x
        y = y + ref.current.clientHeight / 2
      }

      const yWithOffset = placement === 'bottom-middle' ? y + offset : placement === 'top-middle' ? y - offset : y
      const xWithOffset = placement === 'right-middle' ? x + offset : placement === 'left-middle' ? x - offset : x

      setPos((p) => ({ ...p, x: xWithOffset, y: yWithOffset, active: true }))
      timeoutRef.current = setTimeout(() => setPos((p) => ({ ...p, active: true })), showDelay)
    }

    useEffect(() => {
      if (!dialogContent) return
      if (!pos.x && !pos.y && !pos.active) timeoutRef.current && clearTimeout(timeoutRef.current)
      if (hideTooltip && pos.active) setPos((p) => ({ ...p }))
    }, [hideTooltip, pos, dialogContent])

    return (
      <>
        {showDialog && dialogContent ? (
          <ReactPortal>
            <OutClick
              className={cn(
                classes.outClickCustomSelect,
                'outClickSelectBtn'
                // customClasses?.outClick && customClasses.outClick
              )}
              removeListener={!showDialog}
              onClick={() => {
                !pos.active && handleClick()
              }}
            >
              <div
                className={cn(classes.portalTooltipWrapper)}
                style={{
                  transform: transformConfig[placement],
                  // opacity: showDialog ? 1 : 0,
                  left: pos.x,
                  top: pos.y,
                }}
              >
                <div
                  className={cn(
                    classes.portalTooltipContent,
                    triangle && classes.triangledContent,
                    placement,
                    contentClassName && contentClassName,
                    'tooltipContent'
                  )}
                >
                  {dialogContent}
                </div>
              </div>
            </OutClick>
          </ReactPortal>
        ) : null}
        <div
          className={classes?.childrenWrapperClassName}
          onMouseEnter={onMouseEnter}
          onMouseLeave={() => setPos((p) => ({ ...p, active: false }))}
          onClick={(e) => {
            e.stopPropagation()
            handleClick()
          }}
          ref={ref}
        >
          {children}
        </div>
      </>
    )
  }
)
