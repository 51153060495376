import { createAsyncThunk } from '@reduxjs/toolkit'
import { Body, authPost } from '../../../api'
import { urlSynthesis } from '../../../config'

interface ISendPayload {
  pitch: number
  rate: number
  text: string
  voice: string
  volume: number
}

export const ttsSend = createAsyncThunk('tts/ttsSend', async (data: ISendPayload & { id: string }) => {
  const sentDate = Date.now()
  const res = await authPost(urlSynthesis + '/synthesize', { ...data } as Body)
  if (res.data?.response_code === 2) return res.data
  const generationTime = Date.now() - sentDate
  const message = res.data?.response?.[0] ?? {}

  return { ...message, generationTime, id: data.id, pitch: data.pitch, rate: data.rate, volume: data.volume }
})
