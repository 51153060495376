import { useEffect, useState } from 'react'

export const useMobileFilter = (isMobile?: boolean) => {
  const [mobileFilterActive, setMobileFilterActive] = useState(false)

  useEffect(() => {
    if (!isMobile) return

    const scrollHandler = (e: Event) => {
      if (window.pageYOffset > 150 && !mobileFilterActive) setMobileFilterActive(true)
      if (window.pageYOffset < 150 && mobileFilterActive) setMobileFilterActive(false)
    }

    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [isMobile, mobileFilterActive])

  return {
    mobileFilterActive,
  }
}
