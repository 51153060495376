import React, { FC } from 'react'
import classes from '../styles/modal.module.scss'
import cn from 'classnames'

export const Loader: FC<{ size?: number; color?: string }> = ({ color, size = 24 }) => {
  return (
    <div style={{ width: size, height: size }} className={classes.loader}>
      {new Array(3).fill(undefined).map((_, index) => (
        <div key={index} style={{ background: color, width: size / 3, height: size / 3 }} className={cn(classes.dot)} />
      ))}
    </div>
  )
}
