import { FC } from 'react'

import { iconConfig, TIcon } from './config'

export const CustomIcon: FC<{ icon: TIcon }> = ({ icon }) => {
  const Component = iconConfig?.[icon]
  if (!Component) return null

  return <Component />
}
