import { Container } from '../components/container'
import headerClasses from '../styles/header.module.scss'
import classes from '../styles/documentation/documentation.module.scss'
import { IUser } from '../@types/user'
import { useSelector } from '../hooks/redux'

const sections = (user: IUser) => {
  const isAsrEnabled = user.access_asr
  const isTtsEnabled = user.access_tts
  const isConsoleEnabled = user.access_console

  return [
    {
      title: 'Использование API',
      items: [
        ...(isAsrEnabled
          ? [
              {
                title: 'Руководство пользователя облака ASR',
                fileType: 'PDF',
                links: [
                  {
                    title: 'Скачать',
                    href: 'http://gofile.me/4Odjf/n5oxoerOF',
                  },
                ],
              },
            ]
          : []),
        ...(isTtsEnabled
          ? [
              {
                title: 'Руководство пользователя облака TTS',
                fileType: 'PDF',
                links: [
                  {
                    title: 'Скачать',
                    href: 'http://gofile.me/4Odjf/27WimubXF',
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      title: 'Размещение в контуре клиента',
      items: [
        ...(isConsoleEnabled
          ? [
              {
                title: 'Руководство пользователя NLab Speech',
                fileType: 'PDF',
                links: [
                  {
                    title: 'Скачать',
                    href: 'http://gofile.me/4Odjf/Jbzy6bzzU',
                  },
                ],
              },
            ]
          : []),
        ...(isConsoleEnabled
          ? [
              {
                title: 'Руководство администратора NLabSpeech',
                fileType: 'PDF',
                links: [
                  {
                    title: 'Скачать',
                    href: 'http://gofile.me/4Odjf/4FBsgqWPj',
                  },
                ],
              },
            ]
          : []),
      ],
    },
  ]
}

const Documentation = () => {
  const user = useSelector((state) => state.user.user)

  return (
    <>
      <div className={headerClasses.mainTitle}>
        <h1>Документация</h1>
      </div>
      <Container>
        <div className={classes.sections}>
          {sections(user).map((section) => {
            if (!section.items.length) return null

            return (
              <section key={section.title}>
                <h4>{section.title}</h4>
                <div className={classes.sectionItems}>
                  {section.items.map((item, index) => {
                    return (
                      <div key={index + 'section'} className={classes.sectionItem}>
                        <div className={classes.sectionItemTitle}>{item.title}</div>
                        <div className={classes.sectionItemFileInfo}>
                          <span className={classes.sectionFileType}>{item.fileType}</span>
                          <span className={classes.divider} />
                          {item.links.map((item) => (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={item.href}
                              key={item.title + 'btn'}
                              className={classes.sectionItemBtn}
                            >
                              {item.title}
                            </a>
                          ))}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </section>
            )
          })}
        </div>
      </Container>
    </>
  )
}

export default Documentation
