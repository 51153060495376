import { NotifyItem } from '../@types/notify'
import React from 'react'
import { addNotify, removeNotify } from '../store/slices/notify/notifySlice'
import { useDispatch } from 'react-redux'

export const useNotify = () => {
  const dispatch = useDispatch()

  const hideNotify = React.useCallback(
    (item: NotifyItem) => {
      dispatch(removeNotify(item))
    },
    [dispatch]
  )

  const showNotify = React.useCallback(
    (item: Omit<NotifyItem, 'id'>) => {
      dispatch(addNotify(item))
    },
    [dispatch]
  )

  return React.useMemo(
    () => ({
      hideNotify,
      showNotify,
    }),
    [showNotify, hideNotify]
  )
}
