import { FC, memo, useState, MouseEvent, useEffect } from 'react'
import cn from 'classnames'
import { TOptionButton } from '../@types/ui'
import classes from '../styles/optionButtons.module.scss'

export const OptionButtons: FC<{
  buttons: TOptionButton[]
  initialButtonValue: TOptionButton
  handleClick: (option: TOptionButton) => void
  disabled?: boolean
  classes?: { buttonWrapper?: string; buttonItem?: string }
}> = memo(({ buttons, initialButtonValue, handleClick, classes: additionalClasses, disabled }) => {
  const [selected, setSelected] = useState(initialButtonValue)

  const onClickBtn = (e: MouseEvent, btn: TOptionButton) => {
    e.preventDefault()
    handleClick(btn)
    setSelected(btn)
  }

  useEffect(() => {
    setSelected(initialButtonValue)
  }, [buttons, initialButtonValue])

  return (
    <div
      className={cn(classes.optionButtonsWrapper, additionalClasses?.buttonWrapper && additionalClasses?.buttonWrapper)}
    >
      {buttons.map((btn, index) => {
        return (
          <button
            className={cn(
              classes.optionButton,
              selected.value === btn.value && classes.optionsButtonActive,
              additionalClasses?.buttonItem && additionalClasses?.buttonItem
            )}
            key={btn.name + index}
            id={btn.value.toString()}
            disabled={selected.value === btn.value || disabled}
            title={btn.name}
            onClick={(e) => onClickBtn(e, btn)}
          >
            {btn.name}
          </button>
        )
      })}
    </div>
  )
})

OptionButtons.displayName = 'OptionButtons'
