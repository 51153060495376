import { ReactPortal } from './reactPortal'
import classes from '../styles/mobilePopup.module.scss'
import { FC, ReactNode, useEffect, useState, useRef } from 'react'
import { Backdrop } from './modal/backdrop'
import cn from 'classnames'

export const MobilePopup: FC<{ children: ReactNode; show: boolean; handleClose: () => void }> = ({
  children,
  show,
  handleClose,
}) => {
  const [active, setActive] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [startY, setStartY] = useState<number | null>(null)
  const [modalHeight, setModalHeight] = useState<number>(0)
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (modalRef.current) {
      const height = modalRef.current.offsetHeight
      setModalHeight(height)
    }
  }, [active])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    show && (document.body.style.cssText = 'overflow: hidden; position:relative;')

    if (show) {
      setMounted(true)
      timeout = setTimeout(() => setActive(true), 50)
    }

    if (!show) {
      setActive(false)
      document.body.style.cssText = ''
      timeout && clearTimeout(timeout)
    }

    return () => {
      timeout && clearTimeout(timeout)
      document.body.style.cssText = ''
    }
  }, [show])

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
    setStartY(e.touches[0].clientY)
  }

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
    if (!startY) return
    const deltaY = e.touches[0].clientY - startY
    const target = e.target as HTMLElement
    if (deltaY > 50 && !target.closest('input')) {
      handleClose()
      setStartY(null)
    }
  }

  if (!mounted) return null

  return (
    <ReactPortal>
      <>
        <Backdrop show={active} onClick={handleClose} />
        <div
          className={cn(classes.dragHandle, active && classes.dragHandleActive)}
          style={{ transform: active ? `translateY(-${modalHeight + 5}px)` : '' }}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
        />
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTransitionEnd={() => !active && mounted && setMounted(false)}
          className={cn(classes.mobilePopupContent, active && classes.mobilePopupContentActive)}
          ref={modalRef}
        >
          {children}
        </div>
      </>
    </ReactPortal>
  )
}
