import { useSelector } from '../../hooks/redux'
import { memo } from 'react'

import classes from '../../styles/asr/asrMessage.module.scss'
import { StreamingRecordButton } from './streamingRecordButton'
import { TStreamingInfo } from '../../@types/asr'
import { useDispatch } from 'react-redux'
import { asrUpdateStream } from '../../store/slices/asr/asrSlice'

export const StreamingText = memo(() => {
  const dispatch = useDispatch()
  const stream = useSelector((state) => state.asr.stream)
  if (!stream.isStreaming) return null

  return (
    <div className={classes.streamingTextWrapper}>
      <div className={classes.streamBtn}>
        <div onClick={() => dispatch(asrUpdateStream({ isRecordStop: true }))}>
          <StreamingRecordButton isLoading={stream.isRecordStop} isRecording={!stream.isRecordStop} />
        </div>
      </div>
      <div className={classes.streamTexts}>
        {!!stream.data.final?.length &&
          stream.data.final.map((item: any, index) => (
            <div className={classes.streamText} key={index}>
              {item.text}
            </div>
          ))}
        {!!Object.keys(stream.data.notFinal).length && (
          <div className={classes.streamText}>{(stream.data.notFinal as TStreamingInfo).text}</div>
        )}
      </div>

      <div className={classes.streamingInfoText}>Нажмите на кнопку чтобы остановить запись</div>
    </div>
  )
})
