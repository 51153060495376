import React, { FC, FormEvent, Fragment, useEffect, useRef, useState } from 'react'
import classes from '../styles/loginForm.module.scss'
import { CustomButton } from './customButton'
import { login } from '../store/slices/user/async'
import cn from 'classnames'
import { useDispatch, useSelector } from '../hooks/redux'
import { Loader } from './loader'
import { CustomIcon } from './customIcon/customIcon'
import { unwrapResult } from '@reduxjs/toolkit'
import { IUser } from '../@types/user'
import { useNavigate } from 'react-router-dom'

const fields = [
  {
    name: 'token',
    placeholder: 'Токен',
    type: 'text',
    required: true,
  },
]

export const LoginForm: FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const refs = useRef<HTMLInputElement[]>([])
  const isLoading = useSelector((state) => state.user.loading)
  const [formState, setFormState] = useState({ token: '' })
  const [showToken, setShowToken] = useState<boolean>(false)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!formState.token) return
    const data = await dispatch(login(formState.token.trim()))
    const unwrapped: IUser & { response_code: number } = unwrapResult(data)

    if (unwrapped.response_code === 2 || unwrapped.access_asr) return
    if (!unwrapped.access_asr && unwrapped.access_tts) return navigate('/tts/')
    if (!unwrapped.access_asr && !unwrapped.access_tts) return navigate('/documentation/')
  }

  const toggleTokenVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setShowToken(!showToken)
  }

  useEffect(() => {
    const target = refs?.current?.[0]
    if (!target) return
    target.focus()
  }, [])

  return (
    <div className={classes.loginFormWrapper}>
      <form onSubmit={onSubmit} className={classes.loginForm} autoComplete="off">
        <div className={classes.loginFields}>
          {fields.map((item, index) => (
            <Fragment key={item.name + index}>
              <input
                ref={(el) => el && refs.current.push(el)}
                className={classes.loginField}
                type={showToken ? item.type : 'password'}
                name={item.name}
                required={item.required}
                placeholder={item.placeholder}
                value={(formState as any)[item.name] || ''}
                onChange={(e) => setFormState((p) => ({ ...p, [e.target.name]: e.target.value }))}
              />
              {(formState as any)[item.name] && (
                <button className={classes.fieldCross} onClick={toggleTokenVisibility}>
                  <CustomIcon icon={showToken ? 'hideIcon' : 'showIcon'} />
                </button>
              )}
            </Fragment>
          ))}
        </div>
        <CustomButton
          className={cn(classes.loginEnterBtn, !formState.token && classes.disabled, formState.token && classes.active)}
          disabled={!formState.token || isLoading}
          style={{ alignSelf: 'flex-end', minWidth: '30%', justifyContent: 'center' }}
        >
          {isLoading ? <Loader color="#fff" size={20} /> : 'Войти'}
        </CustomButton>
      </form>
    </div>
  )
}
