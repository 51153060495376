import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  playerLink: string
} = {
  playerLink: '',
}

const playerTtsSlice = createSlice({
  name: 'playerTts',
  initialState,
  reducers: {
    ttsStopPLayer: (state, action) => {
      state.playerLink = action.payload
    },
  },
})

const { actions, reducer } = playerTtsSlice

export const { ttsStopPLayer } = actions

export default reducer
