import React from 'react'

import { CommonRoutes, ProtectedRoutes } from './routes'
import { Header } from './layouts/header'

import classes from './styles/header.module.scss'
import { useSelector } from './hooks/redux'
import { NotifyPortal } from './components/notify'

export const App = () => {
  const user = useSelector((state) => state.user.user)

  return (
    <>
      <div className={classes.mainBackground} />
      {user.auth_token ? (
        <>
          <Header />
          <ProtectedRoutes user={user} />
        </>
      ) : (
        <CommonRoutes />
      )}
      <NotifyPortal />
    </>
  )
}
