import { createAsyncThunk } from '@reduxjs/toolkit'
import { urlConsole } from '../../../config'
import axios from 'axios'
import { TConsoleSend, TConsoleDownloadSessions } from '../../../@types/console'
import { setConsoleError, setErrorText } from './consoleSlice'

export const getUsers = createAsyncThunk('console/getUsers', async (auth_token: string, { dispatch }) => {
  const res = await axios.post(urlConsole + '/get_users', { auth_token })

  if (res.data?.response_code === 2) {
    return []
  }

  return res?.data?.users
})

export const consoleSend = createAsyncThunk('console/consoleSend', async (data: TConsoleSend, { dispatch }) => {
  try {
    const stats = await axios.post(urlConsole + '/get_stats', { ...data })
    const sessions = await axios.post(urlConsole + '/get_sessions', { ...data })

    return {
      stats: { 
        ...stats?.data, 
        limit: data.limit, 
        service_type: data.service_type },
      sessions: sessions?.data?.sessions
    }
  } catch (e) {
    dispatch(setConsoleError(true))
    if (e instanceof Error) {
      dispatch(setErrorText(e.message))
    } else {
      dispatch(setErrorText('Error'))
    }
  }
})

export const consoleDownloadSessions = createAsyncThunk('console/consoleDownloadSessions', async (data: TConsoleDownloadSessions, { dispatch }) => {
  const sessions = await axios.post(urlConsole + '/download_sessions', { ...data })

  return sessions

})
