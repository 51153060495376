import { TtsSidebar } from '../../layouts/tts/sidebar'
import { Container } from '../../components/container'
import { TtsMessages } from '../../layouts/tts/messages'

import classes from '../../styles/tts/tts.module.scss'
import headerClasses from '../../styles/header.module.scss'

export const TtsNew = () => {
  return (
    <div className={classes.ttsMain}>
      <div className={headerClasses.mainTitle}>
        <h1>Синтез речи NLab Speech</h1>
      </div>
      <Container>
        <section className={classes.ttsContent}>
          <TtsSidebar />
          <TtsMessages />
        </section>
      </Container>
    </div>
  )
}
