import React from 'react'
import { useNotify } from '../../hooks/notify'
import { NotifyItem as TNotifyItem } from '../../@types/notify'
import classes from '../../styles/notify.module.scss'

export const NotifyItem: React.FC<{ item: TNotifyItem; index: number }> = React.memo(({ item, index }) => {
  const { hideNotify } = useNotify()
  const [mounted, setMounted] = React.useState(false)
  const startPos = index === 0 ? 115 : 0
  const endPos = -index * 115

  React.useEffect(() => {
    let timeout: NodeJS.Timeout = setTimeout(() => {
      setMounted(true)
    }, 80)

    timeout = setTimeout(() => {
      setMounted(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [setMounted])

  const removeAfterTransition = React.useCallback(() => {
    !mounted && hideNotify(item)
  }, [mounted, hideNotify, item])

  return (
    <div
      onTransitionEnd={removeAfterTransition}
      style={{
        transform: `translate(-50%, ${mounted ? endPos : startPos}%)`,
        zIndex: 100 - index,
      }}
      className={`${classes.notify} ${mounted ? classes.notifyMounted : ''}`}
    >
      {/*<button className={classes.notifyExitBtn} onClick={() => setMounted(false)}>*/}
      {/*  <CustomIcon icon="cross" />*/}
      {/*</button>*/}
      <div className={classes.notifyContent}>{item.text}</div>
    </div>
  )
})

NotifyItem.displayName = 'NotifyItem'
