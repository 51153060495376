import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotifyItem, NotifySliceInitialState } from '../../../@types/notify'

const initialState: NotifySliceInitialState = {
  notifyItems: [],
}

export const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    addNotify: (state, action: PayloadAction<Omit<NotifyItem, 'id'>>) => {
      state.notifyItems = [...state.notifyItems, { ...action.payload, id: Date.now() }]
    },
    removeNotify: (state, action: PayloadAction<NotifyItem>) => {
      state.notifyItems = state.notifyItems.filter((item) => item.id !== action.payload.id)
    },
  },
})

// Action creators are generated for each case reducer function
export const { actions, reducer } = notifySlice

export const { removeNotify, addNotify } = actions

export default reducer
