import React from 'react'
import { useSelector } from '../../hooks/redux'
import { NotifyItem } from './notifyItem'
import { ReactPortal } from '../reactPortal'

const NotifyWrapper = React.memo(() => {
  const notifyItems = useSelector((state) => state.notify.notifyItems)

  const sliced = React.useMemo(() => [...notifyItems].slice(0, 3), [notifyItems])

  return (
    <>
      {sliced?.map((item, index) => (
        <NotifyItem item={item} index={index} key={item.id} />
      ))}
    </>
  )
})

export const NotifyPortal: React.FC = React.memo(() => {
  return (
    <ReactPortal>
      <NotifyWrapper />
    </ReactPortal>
  )
})

NotifyPortal.displayName = 'Notify'
NotifyWrapper.displayName = 'NotifyWrapper'
