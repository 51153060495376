import classes from '../../styles/tts/ttsSidebar.module.scss'

export const TtsSidebarHeader = () => {
  /*   const isMobile = useMediaQuery({ query: '(max-width: 576px)' }) */

  return (
    <div className={classes.ttsSidebarHeader}>
      <div className={classes.ttsInfoText}>
        Выберите один или несколько голосов, введите текст и нажмите «Синтезировать»
      </div>
      {/*         {!isMobile && <>{isSidebarSmall ? 'Голоса' : 'Все голоса и настройки'}</>} */}
    </div>
  )
}
