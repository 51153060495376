import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, upload } from '../../../api'
import { urlAsr } from '../../../config'
import { addNotify } from '../notify/notifySlice'
import { logout } from '../user/userSlice'

export const asrUpload = createAsyncThunk('asr/asrUpload', async (params: any, { getState, dispatch }) => {
  const data = new FormData()
  const store = getState()

  for (let field in params) {
    if (field === 'audio_blob') {
      ;[...params[field]].forEach((item) => data.append(field, item))
    } else {
      data.append(field, params[field])
    }
  }

  const res = await upload(params.use_sova ? '/sova' : urlAsr + '/asr', data)

  if (res.data?.response_code === 2) {
    dispatch(addNotify({ text: res.data?.msg || 'Error' }))
    dispatch(logout())
    return null
  }

  console.log(res)

  return { asrSettings: (store as any)?.asr?.selectedAsrSettings, ...res.data }
})

export const getAsrConfig = createAsyncThunk('asr/getArConfig', async () => {
  const res = await get(urlAsr + '/get_config')
  return res.data
})
