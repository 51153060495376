import { CustomIcon } from './customIcon/customIcon'

import classes from '../styles/noResults.module.scss'

export const NoResults = () => {
  return (
    <div
      style={{
        height: '65vh',
      }}
      className={classes.noResults}
    >
      <CustomIcon icon="noResults" />
      <h1 className={classes.title}>Ничего не найдено</h1>
    </div>
  )
}
