const filter = (text: string, searchString: string): any => {
  text = text?.toLowerCase()
  searchString = searchString?.toLowerCase()
  if (!searchString) {
    return true
  }

  if (!searchString.match(/[&|]/)) {
    return text.includes(searchString.trim())
  }
  let startIndex = 0
  let wordsBuffer: any = []
  let quantorsBuffer: any = []
  let isBracket = false
  let bracketCounter = 0
  for (let x = 0; x <= searchString.length; x++) {
    switch (searchString[x]) {
      case '&':
        if (!isBracket) {
          quantorsBuffer.push('&')
          wordsBuffer.push(searchString.substring(startIndex, x).trim())
          startIndex = x + 1
        }
        break
      case '|':
        if (!isBracket) {
          quantorsBuffer.push('|')
          wordsBuffer.push(searchString.substring(startIndex, x).trim())
          startIndex = x + 1
        }
        break
      case '(':
        if (!isBracket) {
          isBracket = true
          startIndex = x + 1
          bracketCounter++
        }
        break
      case ')':
        if (isBracket) {
          if (bracketCounter === 1) {
            wordsBuffer.push(filter(text, searchString.substring(startIndex, x)))
            isBracket = false
            startIndex = x + 1
          }
          bracketCounter--
        }
        break
      default:
        if (x === searchString.length && !isBracket) {
          wordsBuffer.push(searchString.substring(startIndex, x + 1))
        }
    }
  }

  wordsBuffer = wordsBuffer.filter((value: any) => value !== '').map((value: any) => value)
  quantorsBuffer = quantorsBuffer.filter((value: any) => value !== '').map((value: any) => value)

  while (quantorsBuffer.length) {
    const conjuction = quantorsBuffer.indexOf('&')
    if (conjuction >= 0) {
      ;(wordsBuffer[conjuction] as any) = searcher(text, '&', wordsBuffer[conjuction], wordsBuffer[conjuction + 1])
      quantorsBuffer.splice(conjuction, 1)
      wordsBuffer.splice(conjuction + 1, 1)
    } else {
      ;(wordsBuffer[0] as any) = searcher(text, '|', wordsBuffer[0], wordsBuffer[1])
      quantorsBuffer.splice(0, 1)
      wordsBuffer.splice(1, 1)
    }
  }

  return wordsBuffer[0]
}
const searcher = (string: string, quantor: string, word1: string, word2: string) => {
  switch (quantor) {
    case '&':
      return searchResult(word1, string) && searchResult(word2, string)
    default:
      return searchResult(word1, string) || searchResult(word2, string)
  }
}

const searchResult = (word: boolean | string, string: string): boolean | string => {
  return typeof word == 'string' ? string.includes(word) : word
}

export const hasSSMLMarkup = (text: string): boolean => {
  const ssmlRegex = /<speak\b[^>]*>[\s\S]*<\/speak>/
  return ssmlRegex.test(text)
}

const returnArrayOfWords = (searchString: string) => searchString?.split(/[&|]/)

export { filter, returnArrayOfWords }
