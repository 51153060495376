import classNames from 'classnames'
import { Fragment, FC, memo } from 'react'
import { returnArrayOfWords } from '../../functions'
import { IMessagePart } from '../../@types/asr'
import classes from '../../styles/asr/asrMessage.module.scss'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { useSelector } from '../../hooks/redux'
import { copyText } from '../../functions/common'

const transformText = (text: string, searchString: string): string => {
  let newText = text

  const arrayOfSearch = new Set(returnArrayOfWords(searchString))
  arrayOfSearch.forEach((el) => {
    if (el.trim() && text?.includes(el)) {
      newText = newText.replaceAll(el.trim(), `<span class="${classes.serchedText}">${el}</span>`)
    }
  })

  return newText
}

const transformNotFiltredText = (words: any): string => {
  return words.reduce((prevItem: any, curItem: any) => prevItem + ' ' + curItem.word, '')
}

const ToggleText: FC<{
  textArray?: IMessagePart[][]
  text?: string
  title: string
  searchString: string
  player?: any
  id: string
}> = memo(({ title, text, id, textArray, player, searchString }) => {
  const currentTime = useSelector((state) =>
    state.asr.currentAudio.id === id && textArray ? state.asr.currentAudio.currentTime : null
  )

  const handleWordClick = (start: number) => {
    if (!player) return

    player.seekTo(start / player.getDuration())
    player.play()
  }

  const handlerCopyText = async () => {
    const textToCopy = textArray ? textArray?.map((item) => item.map((i) => i.word).join(' ')).join(' ') : text
    await copyText(textToCopy || '')
  }

  return (
    <div className={classNames(classes.asrResultBodyItem)}>
      <p className={classes.asrResultBodyTitle} onClick={handlerCopyText}>
        {title}
        <CustomIcon icon="copy" />
      </p>
      <div className={classNames({ 'asr-result__text': true })}>
        <div className={classes.asrToggleContent}>
          {text && <div dangerouslySetInnerHTML={{ __html: transformText(text, searchString) }} />}
          {textArray?.map((item: IMessagePart[], index: number) => {
            return (
              <Fragment key={index}>
                <div
                  key={index}
                  className={classNames({
                    [classes.asrResultCloud]: !!item[0],
                  })}
                >
                  <div className={classes.asrResultOverlay}>
                    {item?.map((el: IMessagePart, i: number) => {
                      let isActive =
                        currentTime &&
                        currentTime >= el.start &&
                        currentTime <= el.end &&
                        currentTime !== player?.getDuration()

                      return (
                        <Fragment key={i}>
                          <span
                            className={classNames(classes.asrContentSpanWord, isActive && classes.asrWordSelected)}
                            key={i}
                            onClick={() => handleWordClick(el.start)}
                          >
                            {el.word}
                          </span>{' '}
                          {/* DO NOT TOUCH THIS SPACE (it's separator for word's) */}
                        </Fragment>
                      )
                    })}
                  </div>
                  <p
                    className={'d-block'}
                    dangerouslySetInnerHTML={{
                      __html: transformText(transformNotFiltredText(item), searchString),
                    }}
                  />
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
})

export default ToggleText
