import { memo } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import classes from '../styles/header.module.scss'
import { CustomIcon } from '../components/customIcon/customIcon'
import { logout } from '../store/slices/user/userSlice'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { PortalTooltip } from '../components/tooltip/portalTooltip'
import { useSelector } from '../hooks/redux'
import { IUser } from '../@types/user'
import { TIcon } from '../components/customIcon/config'

const links: (user: IUser) => { name: string; icon: string; to: string }[] = (user) => {
  const isTtsAccess = user.access_tts
  const isAsrAccess = user.access_asr
  const isConsoleAccess = user.access_console

  return [
    ...(isAsrAccess
      ? [
          {
            name: 'Распознавание',
            icon: 'asrLogo',
            to: '/',
          },
        ]
      : []),
    ...(isTtsAccess
      ? [
          {
            name: 'Синтез',
            icon: 'ttsLogo',
            to: '/tts',
          },
        ]
      : []),
    // ...(isConsoleAccess
    //   ? [
    //       {
    //         name: 'Консоль',
    //         icon: 'consoleLogo',
    //         to: '/console',
    //       },
    //     ]
    //   : []),
    {
      name: 'Документация',
      to: '/documentation/',
      icon: 'doc',
    },
  ]
}

export const Navigation = memo(() => {
  const { pathname } = useLocation()
  const user = useSelector((state) => state.user.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <nav>
      <ul className={classes.navItemsList}>
        {links(user).map((item, index) => {
          return (
            <PortalTooltip
              key={item.name + index}
              triangle={false}
              placement="bottom-middle"
              tooltipContent={item.name}
            >
              <li
                className={cn(
                  classes.navItem,
                  (pathname === item.to || pathname === item.to.slice(0, -1)) && classes.navItemActive
                )}
                key={item.to + item.name}
              >
                <Link to={item.to}>
                  <CustomIcon icon={item.icon as TIcon} />
                </Link>
              </li>
            </PortalTooltip>
          )
        })}
        <PortalTooltip triangle={false} placement="bottom-middle" tooltipContent="Выход">
          <li className={classes.navItem}>
            <button onClick={handleLogout}>
              <CustomIcon icon="logout" />
            </button>
          </li>
        </PortalTooltip>
      </ul>
    </nav>
  )
})
