import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { urlAsr, urlSova } from '../../../config'
import { v4 as uuidv4 } from 'uuid'
import {
  IAsrConfig,
  IMessage,
  IMessagePart,
  IServerAsrConfig,
  Status,
  TStatus,
  TStreamingInfo,
} from '../../../@types/asr'
import { asrUpload, getAsrConfig } from './async'
import { getLocalStorageInfo, setLocalStorageInfo } from '../../../tools'
import { initialFilterLanguages } from '../tts/ttsSlice'

const initialState: {
  messages: IMessage[]
  status: Status
  searchString: string
  selectedAsrSettings: IAsrConfig
  config: {
    status: TStatus
    error: null | string
    data: IServerAsrConfig | {}
  }
  activeFilters: {
    language: string[]
    stream_recognition: number
  }
  stream: {
    isStreaming: boolean
    isRecordStop: boolean
    data: {
      notFinal: TStreamingInfo | {}
      final: TStreamingInfo[]
    }
  }
  currentAudio: {
    id: string
    currentTime: number
  }
} = {
  messages: getLocalStorageInfo('asr')?.messages || [],
  status: Status.start,
  searchString: '',
  selectedAsrSettings: {
    convert_digits: 1,
    use_punctuation: 1,
    stream_recognition: 0,
    translate: 1,
    classify: 0,
    restore_case: 1,
    profanity_filter: 0,
    include_breaks: 0,
    sample_rate: '16000',
    decoder_name: '',
    language: '',
    audio_blob: '',
    split_channels: 0,
    vad_type: '',
  },
  activeFilters: {
    language: initialFilterLanguages,
    stream_recognition: -1,
  },
  config: {
    status: 'success',
    error: null,
    data: {},
  },
  stream: {
    isStreaming: false,
    isRecordStop: true,
    data: {
      final: [],
      notFinal: {},
    },
  },
  currentAudio: {
    id: '',
    currentTime: 0,
  },
}

const asrSlice = createSlice({
  name: 'asr',
  initialState,
  reducers: {
    asrSearch: (state, action) => {
      state.searchString = action.payload
    },
    asrUpdateFilters: (state, action) => {
      state.activeFilters = { ...state.activeFilters, ...action.payload }
    },
    asrHideShowText: (state, action) => {
      const messages = state.messages.map((item) => ({
        ...item,
        [action.payload.key]: action.payload.value,
      }))

      return {
        ...state,
        messages,
      }
    },
    asrHideShowMessage: (state, action) => {
      if (action.payload?.id) {
        const messages = state.messages.map((el) =>
          el.id === action.payload.id ? { ...el, isMessageOpen: !el.isMessageOpen } : el
        )

        setLocalStorageInfo('asr', { ...getLocalStorageInfo('asr'), messages })

        return {
          ...state,
          messages,
        }
      }

      const messages = state.messages.map((item) => ({ ...item, isMessageOpen: action.payload?.value ?? false }))
      setLocalStorageInfo('asr', { ...getLocalStorageInfo('asr'), messages })

      return {
        ...state,
        messages,
      }
    },

    asrStreamingUploadSuccess: (state, action) => {
      let itemArr: IMessage = {
        audio_url: action.payload?.audio_url || '',
        textFilterd: action.payload.textFilterd,
        text: action.payload?.text || [],
        textNotFiltred: '',
        recognition_time: 0,
        textFiltredShow: true,
        textNotFiltredShow: true,
        currentTime: 0,
        class: false,
        isPlayning: false,
        id: uuidv4(),
        transcription: '',
        filename: 'stream',
        stream_recognition: 1,
        language: state.selectedAsrSettings.language,
        asrSettings: state.selectedAsrSettings,
        isMessageOpen: true,
      }
      const messages = [itemArr, ...state.messages]
      state.messages = messages
      setLocalStorageInfo('asr', { ...getLocalStorageInfo('asr'), messages })
    },
    asrSetAudio: (state, action) => {
      if (action.payload?.id && action.payload?.id !== state.currentAudio.id) state.currentAudio.id = action.payload.id
      if (action.payload.currentTime) state.currentAudio.currentTime = action.payload.currentTime
    },
    asrSetCurrentTime: (state, action) => {
      state.messages = state.messages.map((el) => {
        if (el?.id === action.payload?.id) {
          el.currentTime = action.payload?.time
        }
        return el
      })
    },
    asrSetIsPlayning: (state, action) => {
      state.messages = state.messages.map((el) => {
        if (el?.id === action.payload?.id) {
          el.isPlayning = action.payload?.isPlayning
        }
        return el
      })
    },

    asrClearMessage: (state) => {
      state.messages = []
      setLocalStorageInfo('asr', { ...getLocalStorageInfo('asr'), messages: [] })
    },
    asrDeleteMessage: (state, action) => {
      if (!action.payload?.id) return { ...state }

      const messages = state.messages.filter((item) => item.id !== action.payload.id)
      setLocalStorageInfo('asr', { ...getLocalStorageInfo('asr'), messages })

      return { ...state, messages }
    },
    asrSetStatus: (state, action) => {
      state.status = action.payload?.status
    },
    asrUpdateSettings: (state, action: PayloadAction<IAsrConfig>) => {
      state.selectedAsrSettings = action.payload
    },
    asrUpdateStream: (state, action) => {
      state.stream = { ...state.stream, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asrUpload.pending, (state) => {
      state.status = Status.loading
    })
    builder.addCase(asrUpload.fulfilled, (state, action) => {
      if (!action.payload) return { ...state, status: Status.start }

      let multichannel = false
      const itemsArray: IMessage[] = []
      const language = action.payload.asrSettings?.language ?? ''
      const stream_recognition = action.payload.asrSettings?.stream_recognition ?? 0

      if (action.payload?.r.length) {
        action.payload.r.forEach((element: any) => {
          const message = element.response
          if (!element?.response[0].response_audio_url) {
            state.status = Status.start
            return
          }

          let items: any = []
          message.map((el: any, i: number) => {
            if (el.channel === 1) {
              multichannel = true
            }
            el.words = el.words.map((e: any) => {
              e.channel = el.channel ? el.channel : 0
              return e
            })
            items.push(el?.words)
          })

          if (multichannel) {
            let response: any = []
            items.map((el: any) => {
              response = response.concat(el)
            })
            response.sort((a: any, b: any) => {
              if (a.start > b.start) {
                return 1
              } else {
                return -1
              }
            })
            items = [[]]
            let currentChannel = response[0].channel,
              currentIndex = 0
            response.map((el: any, i: number) => {
              try {
                if (el.channel === currentChannel) {
                  items[currentIndex].push(el)
                } else {
                  currentIndex++
                  currentChannel = el.channel
                  items.push([])
                  items[currentIndex].push(el)
                }
              } catch (e) {
                console.log(e)
              }
            })
          }
          let filtredText: any = ''
          try {
            element.response.map((el: any) => {
              filtredText += el.text + ' '
            })
          } catch (e) {
            console.log(e)
          }

          let audio_url = action?.payload?.sova_asr ? urlSova : urlAsr + element?.response?.[0]?.response_audio_url
          let item: IMessage = {
            audio_url: audio_url?.split('&channel')[0] || '',
            textFilterd: filtredText,
            text: items,
            textNotFiltred: element?.response?.[0].words?.map((e: IMessagePart) => e?.word).join(''),
            recognition_time: element?.response?.[0]?.recognition_time,
            textFiltredShow: true,
            textNotFiltredShow: true,
            currentTime: 0,
            language,
            stream_recognition,
            asrSettings: action.payload.asrSettings,
            class: !!element?.response?.[0]?.class,
            isPlayning: false,
            id: uuidv4(),
            transcription: (action.payload.sova_asr ? urlSova : urlAsr) + element?.response_transcript_url,
            filename: element?.original_filename,
            isMessageOpen: true,
          }
          itemsArray.push(item)
        })
        const newState = [...itemsArray, ...state.messages]
        state.status = Status.start
        state.messages = newState
        setLocalStorageInfo('asr', { ...getLocalStorageInfo('asr'), messages: newState })
        return
      } else {
        state.status = Status.start
        return
      }
    })
    builder.addCase(asrUpload.rejected, (state) => {
      state.status = Status.start
    })
    builder.addCase(getAsrConfig.pending, (state) => {
      state.config.status = 'loading'
    })
    builder.addCase(getAsrConfig.fulfilled, (state, action) => {
      const config: {
        status: TStatus
        error: null | string
        data: IServerAsrConfig
      } = {
        data: action.payload?.response,
        status: 'success',
        error: null,
      }

      const initialLanguage = 'ru'
      const initialDecoder = config?.data?.decoders?.[initialLanguage]?.[0] ?? 'general'
      const initialSampleRate = config?.data?.['acoustic_models']?.[initialLanguage]?.[0].toString() ?? '8000'
      const initialVadType = config?.data?.['vad_type']?.[0] ?? 'fast'

      return {
        ...state,
        config,
        selectedAsrSettings: {
          ...state.selectedAsrSettings,
          language: initialLanguage,
          decoder_name: initialDecoder,
          sample_rate: initialSampleRate,
          vad_type: initialVadType,
        },
      }
    })
  },
})

const { actions, reducer } = asrSlice

export const {
  asrClearMessage,
  asrDeleteMessage,
  asrHideShowText,
  asrSearch,
  asrSetStatus,
  asrSetAudio,
  asrStreamingUploadSuccess,
  asrUpdateSettings,
  asrUpdateFilters,
  asrHideShowMessage,
  asrUpdateStream,
} = actions

export default reducer
