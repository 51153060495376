import { FC } from 'react'
import asrClasses from '../../styles/asr/asrSidebar.module.scss'

export const Checkbox: FC<{ isChecked: boolean; handleCheckboxChange: () => void }> = ({
  isChecked,
  handleCheckboxChange,
}) => {
  return (
    <label>
      <input className={asrClasses.checkBoxInput} type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
    </label>
  )
}
