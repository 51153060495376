import { useSelector } from '../../../hooks/redux'
import { CustomButton } from '../../../components/customButton'
import { memo } from 'react'
import { languageTranslate } from '../settings/config'
import { useDispatch } from 'react-redux'
import { asrUpdateFilters } from '../../../store/slices/asr/asrSlice'
import cn from 'classnames'
import { initialFilterLanguages } from '../../../store/slices/tts/ttsSlice'

import classes from '../../../styles/filter.module.scss'

export const ListFilters = memo(() => {
  const dispatch = useDispatch()
  const selectedLanguages = useSelector((state) => state.asr.activeFilters.language)
  const selectedStreamRecognition = useSelector((state) => state.asr.activeFilters.stream_recognition)

  const handleSetFilter = (val: string) => {
    if (selectedLanguages.includes(val)) {
      const filtered = selectedLanguages.filter((lng) => lng !== val)
      return dispatch(asrUpdateFilters({ language: filtered.length === 0 ? initialFilterLanguages : filtered }))
    }

    dispatch(asrUpdateFilters({ language: [...selectedLanguages, val] }))
  }

  return (
    <div className={classes.asrListFiltersWrapper}>
      <div className={classes.asrListFilterItem}>
        <span className={classes.filterTextTitle}>Распознавание</span>
        <div className={classes.asrListFilterBtns}>
          <CustomButton
            viewType="outline"
            className={cn(selectedStreamRecognition !== 1 && classes.active)}
            onClick={() =>
              dispatch(
                asrUpdateFilters({
                  stream_recognition: selectedStreamRecognition === 0 ? -1 : selectedStreamRecognition === -1 ? 1 : -1,
                })
              )
            }
          >
            Пакетное
          </CustomButton>
          <CustomButton
            viewType="outline"
            className={cn(selectedStreamRecognition !== 0 && classes.active)}
            onClick={() =>
              dispatch(
                asrUpdateFilters({
                  stream_recognition: selectedStreamRecognition === 1 ? -1 : selectedStreamRecognition === -1 ? 0 : -1,
                })
              )
            }
          >
            Потоковое
          </CustomButton>
        </div>
      </div>
      <div className={classes.asrListFilterItem}>
        <span className={classes.filterTextTitle}>Выбор языка</span>
        <div className={classes.asrListFilterBtns}>
          {initialFilterLanguages.map((lng) => {
            return (
              <CustomButton
                key={lng}
                viewType="outline"
                className={cn(selectedLanguages.includes(lng) && classes.active)}
                onClick={() => handleSetFilter(lng)}
              >
                {languageTranslate[lng]}
              </CustomButton>
            )
          })}
        </div>
      </div>
    </div>
  )
})

ListFilters.displayName = 'ListFilters'
