import classes from '../../styles/tts/ttsModal.module.scss'
import { FC } from 'react'
import cn from 'classnames'
import { EmotionListItemType } from './ttsModal/ttsModalVoices'

export const EmotionListItem: FC<{
  onClick: (name: string) => void
  itemConfig: EmotionListItemType
  selectedEmotion: string | null | undefined
}> = ({ itemConfig, onClick, selectedEmotion }) => {
  return (
    <div
      className={cn(classes.emojiDropdownItem, selectedEmotion === itemConfig.name && classes.emojiDropdownActiveItem)}
      onClick={(e) => {
        e.stopPropagation()
        onClick(itemConfig.name)
      }}
    >
      <img className={classes.emojiDropdownImg} src={itemConfig.url} alt="name" />
      <span>{itemConfig.tittle}</span>
    </div>
  )
}

export const EmotionList: FC<{
  config: EmotionListItemType[]
  onClick: (name: string) => void
  selectedEmotion: string | null | undefined
}> = ({ config, onClick, selectedEmotion }) => {
  return (
    <div className={classes.emojiDropdownWrapper}>
      {config.map((item) => {
        return <EmotionListItem onClick={onClick} itemConfig={item} selectedEmotion={selectedEmotion} />
      })}
    </div>
  )
}
