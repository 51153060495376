import { RouteObject, useRoutes } from 'react-router-dom'
import { Asr } from './pages/Asr/Asr'
import Documentation from './pages/Documentaton'
import NoMatch from './pages/NoMatch'
import { FC } from 'react'
import { LoginForm } from './components/loginForm'
import { TtsNew } from './pages/TtsNew'
import { StatisticsConsole } from './pages/StatisticsConsole/StatisticsConsole'
import { IUser } from './@types/user'
import AccessDenied from './pages/AccessDenied'

const protectedRoutes: (user: IUser) => RouteObject[] = (user) => {
  const isTtsAccess = user.access_tts
  const isAsrAccess = user.access_asr
  const isConsoleAccess = user.access_console

  return [
    {
      path: '/',
      children: [
        ...(isAsrAccess
          ? [
              {
                element: <Asr />,
                index: true,
              },
            ]
          : []),
        ...(isTtsAccess
          ? [
              {
                path: 'tts',
                element: <TtsNew />,
              },
            ]
          : []),
        {
          path: 'documentation',
          element: <Documentation />,
        },
        {
          path: '*',
          element: <NoMatch />,
        },
      ],
    },
  ]
}

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        element: <LoginForm />,
        index: true,
      },
      {
        path: 'tts',
        element: <AccessDenied />,
      },
      {
        path: 'documentation',
        element: <AccessDenied />,
      },
      {
        path: '*',
        element: <NoMatch />,
      },
    ],
  },
]

export const ProtectedRoutes: FC<{ user: IUser }> = ({ user }) => useRoutes(protectedRoutes(user))
export const CommonRoutes = () => useRoutes(routes)
