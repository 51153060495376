import { FC, memo, useEffect, useState } from 'react'
import { CustomIcon } from './customIcon/customIcon'
import customSelectClasses from '../styles/customSelect.module.scss'
import { TCustomSelectItem } from '../@types/ui'
import { OutClick } from './outClick'
import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { MobilePopup } from './mobilePopup'
import modalClasses from '../styles/mobilePopup.module.scss'
import { Checkbox } from './checkbox/checkbox'

const ITEM_HEIGHT = 39
const MAX_ITEMS = 5

export const CustomSelect: FC<{
  items: TCustomSelectItem[]
  initialValue: TCustomSelectItem
  mobileTitle?: string
  handleChange: (option: TCustomSelectItem) => void
  classes?: {
    outClickClassName?: string
    selectorWrapperClassName?: string
    selectListItemsClassName?: string
    selectItem?: string
  }
}> = memo(({ items, handleChange, initialValue, classes, mobileTitle }) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(initialValue)
  const [showMobilePopup, setShowMobilePopup] = useState<boolean>(false)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })

  const handleCloseOpen = () => {
    setOpen((p) => !p)
    setShowMobilePopup((p) => !p)
  }

  const handleClickItem = (item: TCustomSelectItem) => {
    handleChange(item)
    setSelected(item)
    !isLaptopOrMobile && handleCloseOpen()
  }

  useEffect(() => {
    // if items length changed and initialValue didn't change
    setSelected(initialValue)
  }, [handleChange, initialValue, items])

  return (
    <OutClick
      className={cn(customSelectClasses.outClickWrapper, classes?.outClickClassName && classes.outClickClassName)}
      onClick={open && !isLaptopOrMobile ? handleCloseOpen : () => null}
    >
      <>
        <div
          className={cn(customSelectClasses.customSelectWrapper, classes?.selectorWrapperClassName)}
          onClick={handleCloseOpen}
        >
          <div className={customSelectClasses.customSelectActive}>
            <span>{selected.name}</span> <CustomIcon icon={open && !isLaptopOrMobile ? 'arrowDown' : 'arrowRight'} />
          </div>
        </div>
        {open && !isLaptopOrMobile && (
          <div
            style={{ maxHeight: MAX_ITEMS * ITEM_HEIGHT }}
            className={cn(
              customSelectClasses.customSelectListItems,
              classes?.selectListItemsClassName && classes?.selectListItemsClassName,
              open && customSelectClasses.customSelectorOpened
            )}
          >
            {[selected, ...items.filter((item) => item.value !== selected.value)].map((item, index) => {
              // if (item.value === selected.value)
              return (
                <div
                  key={item.name + index}
                  className={cn(
                    customSelectClasses.customSelectItem,
                    classes?.selectItem && classes.selectItem,
                    item.value === selected.value && customSelectClasses.active
                  )}
                  onClick={() => handleClickItem(item)}
                >
                  <span>{item.name}</span>
                </div>
              )
            })}
          </div>
        )}
        {isLaptopOrMobile && mobileTitle && (
          <MobilePopup show={showMobilePopup} handleClose={() => setShowMobilePopup(false)}>
            <div className={modalClasses.modalPopupTitleWrapper}>
              <div className={modalClasses.mobilePopupTitle}>{mobileTitle}</div>
              <button onClick={() => setShowMobilePopup(false)} className={modalClasses.mobilePopupCloseBtn}>
                Закрыть
              </button>
            </div>
            <div className={modalClasses.mobilePopupWrapper}>
              {items.map((item, index) => {
                // if (item.value === selected.value)
                return (
                  <div
                    key={item.name + index}
                    className={cn(
                      customSelectClasses.customSelectItem,
                      item.value === selected.value && customSelectClasses.active
                    )}
                    onClick={() => handleClickItem(item)}
                  >
                    <span>{item.name}</span>
                    <Checkbox
                      isChecked={item.value === selected.value}
                      handleCheckboxChange={() => handleClickItem(item)}
                    />
                  </div>
                )
              })}
            </div>
          </MobilePopup>
        )}
      </>
    </OutClick>
  )
})

CustomSelect.displayName = 'CustomSelect'
