export interface IMessagePart {
  word: string
  start: number
  end: number
  selected?: boolean | null
  channel: number
  nomargin?: boolean
}

export type TWords = IMessagePart[]

export type TStreamingInfo = {
  text: string
  confidence: number
  words: IMessagePart[]
}

export interface IMessage {
  audio_url: string
  textFilterd: string
  text: TWords[]
  textNotFiltred: string
  textFiltredShow: boolean
  textNotFiltredShow: boolean
  isPlayning: boolean
  recognition_time: number
  currentTime: number
  class: boolean
  id: string
  filename: string
  language: string
  isMessageOpen: boolean
  transcription: string
  isStreamRecognition?: boolean
  stream_recognition: number
  asrSettings: IAsrConfig
}

export enum Status {
  start = 'start',
  loading = 'loading',
  pause = 'pause',
}

export type TStatus = 'loading' | 'failed' | 'success'

export interface IStateAsr {
  messages: IMessage[]
  status: Status
  searchString: string
}

export interface IAsrConfig {
  convert_digits: number
  use_punctuation: number
  translate: number
  classify: number
  restore_case: number
  profanity_filter: number
  include_breaks: number
  sample_rate: string
  decoder_name: string
  language: string
  audio_blob: string | string[]
  split_channels: number
  vad_type: string
  stream_recognition: number
}

export interface IServerAsrConfig {
  acoustic_models: { [p: string]: string[] }
  decoders: { [p: string]: string[] }
  denoiser_models: number[]
  languages: string[]
  vad_type: string[]
}
