import { CustomButtonSelect } from '../../components/customButtonSelect'
import { CustomButton } from '../../components/customButton'
import { CustomIcon } from '../../components/customIcon/customIcon'
import { TtsModalSettings } from './ttsModal/ttsModalSettings'
import { Dispatch, FC, memo, SetStateAction, useContext, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import classes from '../../styles/tts/ttsSidebar.module.scss'
import voicesClasses from '../../styles/tts/ttsSidebarVoices.module.scss'
import { TextAreaCtx } from './sidebar'
import { examplesConfig } from './config'
import { getRandomInt } from '../../functions/common'
import cn from 'classnames'

const AdviceContent = () => {
  return (
    <div className={classes.ttsSidebarAdviceContent}>
      <p>
        Для изменения ударения в словах, используйте «+» перед ударной гласной: з+амок, зам+ок. Чтобы интонационно
        выделить все слово используйте «*» перед словом
      </p>
      <p>
        Мы также поддерживаем ssml теги:
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">
          ssml документация <CustomIcon icon="back" />
        </a>
      </p>
    </div>
  )
}

const Examples: FC<{ setOpen?: Dispatch<SetStateAction<boolean>> }> = ({ setOpen }) => {
  const { setTextareaValue } = useContext(TextAreaCtx)

  const handleClick = (phrases: string[]) => () => {
    if (!phrases.length) return
    const rnd = getRandomInt(0, phrases.length - 1)
    setTextareaValue(phrases[rnd])
    setOpen && setOpen(false)
  }

  return (
    <div className={classes.examplesBtnWrapper}>
      {examplesConfig.map((item, index) => {
        return (
          <CustomButton className={classes.examplesBtn} key={item.title + index} onClick={handleClick(item.phrases)}>
            {item.title}
          </CustomButton>
        )
      })}
    </div>
  )
}

export const SidebarButtons: FC<{ isMobile?: boolean }> = memo(({ isMobile }) => {
  const [showPopup, setShowPopup] = useState(false)
  const handleCloseOpenPopup = () => setShowPopup((p) => !p)
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isSmallScreen = useMediaQuery({ query: '(max-width: 400px)' })

  return (
    <div className={classes.ttsSidebarBtns}>
      <button onClick={handleCloseOpenPopup} className={cn(voicesClasses.voicesBtn, classes.ttsSidebarSelectBtn)}>
        <CustomIcon icon="settings" />
        Настройки
      </button>
      <CustomButtonSelect
        classes={{ button: classes.ttsSidebarSelectBtn, content: classes.ttsSidebarSelectContent }}
        triangle={true}
        placement={isSmallScreen ? 'top-left' : 'top-middle'}
        contentComponent={<AdviceContent />}
      >
        <CustomIcon icon="question" />
        {!isMobile && 'Советы'}
      </CustomButtonSelect>
      <CustomButtonSelect
        classes={{
          button: classes.ttsSidebarSelectBtn,
          content: cn(classes.ttsSidebarSelectContent, classes.examples),
        }}
        triangle={true}
        placement={!isLaptopOrMobile ? 'top-middle' : 'top-left'}
        contentComponent={<Examples />}
        cloneChild
      >
        <CustomIcon icon="examples" />
        {!isMobile && 'Примеры фраз'}
      </CustomButtonSelect>
      <TtsModalSettings handleClose={handleCloseOpenPopup} showPopup={showPopup} />
      {/* <a href="/">
        <CustomButton className={classes.ttsSidebarSelectBtn}>
          <CustomIcon icon="question" />
          Видеоинструкция
        </CustomButton>
      </a> */}
    </div>
  )
})
