import { FC, ReactNode, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import classes from '../../styles/modal.module.scss'
import { Backdrop, IBackdrop } from './backdrop'
import cn from 'classnames'
import { CustomIcon } from '../customIcon/customIcon'
import { CustomButton } from '../customButton'

interface IModal {
  children: ReactNode
  isOpen: boolean
  handleClose: () => void
  backdrop?: boolean
  backdropProps?: Omit<IBackdrop, 'onClick'>
  title?: string | ReactNode
  classes?: { modalWrapper?: string; modalContent?: string }
  subtitle?: string
}

const ModalContent: FC<
  Omit<
    IModal & { active: boolean; btnText?: string; className?: string },
    'backdrop' | 'backdropProps' | 'isOpen' | 'classes'
  >
> = ({ title, subtitle, handleClose, children, active, className, btnText }) => {
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div className={cn(classes.modal, active && classes.modalActive, className && className)}>
      <div className={classes.modalHeader}>
        {title && <span className={classes.modalTitle}>{title}</span>}
        <button onClick={handleClose} className={classes.modalCloseBtn}>
          {!isLaptopOrMobile || title === 'Настройки' ? (
            <CustomIcon icon="cross" />
          ) : (
            <CustomButton onClick={handleClose}>Добавить</CustomButton>
          )}
        </button>
      </div>
      {subtitle && <span className={classes.modalSubtitle}>{subtitle}</span>}
      <div className={classes.modalContent}>{children}</div>
    </div>
  )
}

export const Modal: FC<IModal> = ({
  children,
  isOpen,
  classes: customClasses,
  handleClose,
  backdrop = true,
  backdropProps,
  title,
  subtitle,
}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null)
    document.addEventListener('keydown', closeOnEscapeKey)
    if (!isOpen) {
      document.removeEventListener('keydown', closeOnEscapeKey)
      setActive(false)
    }
    if (isOpen) setTimeout(() => setActive(true), 100)
    return () => {
      document.removeEventListener('keydown', closeOnEscapeKey)
    }
  }, [handleClose, isOpen])

  if (!isOpen) return null

  if (backdrop && isOpen) {
    return (
      <>
        <Backdrop show={active} zIndex={backdropProps?.zIndex} onClick={handleClose} />
        <ModalContent
          className={cn(customClasses?.modalContent && customClasses?.modalContent)}
          children={children}
          handleClose={handleClose}
          active={active}
          title={title}
          subtitle={subtitle}
        />
      </>
    )
  }

  return (
    <ModalContent
      className={cn(customClasses?.modalContent && customClasses?.modalContent)}
      children={children}
      handleClose={handleClose}
      active={active}
      title={title}
      subtitle={subtitle}
    />
  )
}
