import { createAsyncThunk } from '@reduxjs/toolkit'
import { urlAsr } from '../../../config'
import axios from 'axios'
import { addNotify } from '../notify/notifySlice'
import { setLoading } from './userSlice'

export const login = createAsyncThunk('user/auth', async (auth_token: string, { dispatch }) => {
  const res = await axios.post(urlAsr + '/auth', { auth_token })

  if (res.data?.response_code === 2) {
    dispatch(addNotify({ text: res.data?.msg || 'Error' }))
    dispatch(setLoading(false))
  }

  return {
    auth_username: res.data?.auth_username,
    user_id: res.data?.user_id,
    auth_token: res.data?.auth_token,
    access_console: res.data?.access_console,
    access_asr: res.data?.access_asr,
    access_tts: res.data?.access_tts,
    access_admin: res.data?.access_admin,
    response_code: res.data?.response_code
  }
})
