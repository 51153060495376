import { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { CustomButton } from '../../../components/customButton'
import { CustomIcon } from '../../../components/customIcon/customIcon'
import { asrClearMessage, asrHideShowMessage, asrSearch } from '../../../store/slices/asr/asrSlice'

import classes from '../../../styles/filter.module.scss'
import ttsClasses from '../../../styles/tts/ttsFilters.module.scss'
import modalClasses from '../../../styles/mobilePopup.module.scss'
import { CustomButtonSelect } from '../../../components/customButtonSelect'
import { ShowHideTextFilter } from './showHideTextFilter'
import { useSelector } from '../../../hooks/redux'
import { PortalTooltip } from '../../../components/tooltip/portalTooltip'
import asrClasses from '../../../styles/filter.module.scss'
import { useMobileFilter } from '../../../hooks/common'
import cn from 'classnames'
import { ListFilters } from './listFilters'
import { MobilePopup } from '../../../components/mobilePopup'
import mGlass from '../../../assets/icons/m-glass.svg'

export const AsrFilters = memo(() => {
  const dispatch = useDispatch()
  const isLaptopOrMobile = useMediaQuery({ query: '(max-width: 970px)' })
  const { mobileFilterActive } = useMobileFilter(isLaptopOrMobile)
  const isAnybodyOpened = useSelector((state) => state.asr.messages.some((item) => item.isMessageOpen))
  const isMediumScreen = useMediaQuery({ query: '(max-width: 1314px)' })
  const searchString = useSelector((state) => state.asr.searchString)
  const [modalSettings, setModalSettings] = useState({ active: false, content: 'text' })

  const handleFiltredChanged = (event: any) => {
    dispatch(asrSearch(event.target.value))
  }

  const handleClear = () => {
    dispatch(asrClearMessage())
  }

  const showHidePopup = (content?: string) =>
    setModalSettings((p) => ({
      active: !p.active,
      content: content ?? p.content,
    }))

  const handleClearInput = () => {
    dispatch(asrSearch(''))
  }

  return (
    <div
      className={cn(
        ttsClasses.ttsFiltersWrapper,
        isLaptopOrMobile && ttsClasses.ttsFilterMobile,
        mobileFilterActive && isLaptopOrMobile && ttsClasses.ttsFilterMobileActive
      )}
    >
      <div className={ttsClasses.ttsFilterInputBox}>
        <img className={ttsClasses.ttsFilterInputIcon} src={mGlass} alt="" />
        <input
          value={searchString}
          type="text"
          className={classes.asrFilterInput}
          placeholder="Поиск"
          onChange={handleFiltredChanged}
        />
        {searchString && (
          <button onClick={handleClearInput}>
            <CustomIcon icon="cross" />
          </button>
        )}
      </div>
      {!(isLaptopOrMobile && searchString) && (
        <div className={ttsClasses.ttsFilterButtons}>
          <CustomButtonSelect
            hideSelect={isLaptopOrMobile}
            classes={{ button: asrClasses.filterBtn }}
            contentComponent={<ShowHideTextFilter />}
          >
            <PortalTooltip placement="top-middle" tooltipContent="Колонки">
              <div
                {...(isLaptopOrMobile && { onClick: () => showHidePopup('text') })}
                className={asrClasses.tooltipFilterClassName}
              >
                <CustomIcon icon="columns" />
              </div>
            </PortalTooltip>
          </CustomButtonSelect>
          <CustomButtonSelect
            hideSelect={isLaptopOrMobile}
            classes={{ button: asrClasses.filterBtn }}
            contentComponent={<ListFilters />}
          >
            <PortalTooltip placement="top-middle" tooltipContent="Фильтры">
              <div
                {...(isLaptopOrMobile && { onClick: () => showHidePopup('filters') })}
                className={asrClasses.tooltipFilterClassName}
              >
                <CustomIcon icon="filter" />
              </div>
            </PortalTooltip>
          </CustomButtonSelect>
          <CustomButton
            className={classes.asrFilterCustomButton}
            onClick={() => dispatch(asrHideShowMessage(isAnybodyOpened ? {} : { value: true }))}
          >
            {!isMediumScreen ? (
              <>{isAnybodyOpened ? 'Свернуть все' : 'Развернуть все'}</>
            ) : (
              <CustomIcon icon={isAnybodyOpened ? 'arrowUp' : 'arrowDown'} />
            )}
          </CustomButton>
          <CustomButton className={classes.asrFilterCustomButton} onClick={handleClear}>
            {!isMediumScreen ? 'Очистить все' : <CustomIcon icon="clear" />}
          </CustomButton>
        </div>
      )}
      {isLaptopOrMobile && (
        <MobilePopup show={modalSettings.active} handleClose={() => showHidePopup()}>
          <div className={modalClasses.modalPopupTitleWrapper}>
            <div className={modalClasses.mobilePopupTitle}>
              {modalSettings.content === 'text' ? 'Показывать текст' : 'Фильтры'}
            </div>
            <button onClick={() => showHidePopup()} className={modalClasses.mobilePopupCloseBtn}>
              Закрыть
            </button>
          </div>
          {modalSettings.content === 'text' && <ShowHideTextFilter isMobile={isLaptopOrMobile} />}
          {modalSettings.content === 'filters' && <ListFilters />}
        </MobilePopup>
      )}
    </div>
  )
})

AsrFilters.displayName = 'Filter'
